<vdr-page-block>
  <vdr-action-bar>
    <vdr-ab-left></vdr-ab-left>
    <vdr-ab-right>
      <div class="flex clr-row">
        <button
          [hidden]="!('SuperAdmin' | hasPermission)"
          [disabled]="(jofemar$ | async)?.isSyncing"
          class="btn btn-secondary"
          (click)="syncJofemar()"
        >
          <span *ngIf="(jofemar$ | async)?.isSyncing">
            <clr-spinner clrInline />
          </span>
          Synchroniseer
        </button>
        &nbsp;
        <button
          [hidden]="!('SuperAdmin' | hasPermission)"
          class="btn btn-danger"
          (click)="resetToFactorySettings()"
        >
          Herstel naar fabrieksinstellingen
        </button>
      </div>
    </vdr-ab-right>
  </vdr-action-bar>
</vdr-page-block>

<vdr-split-view
  [rightPanelOpen]="activeJofemarChannel$ | async"
  (closeClicked)="closeChannels()"
>
  <ng-template vdrSplitViewLeft>
    <vdr-data-table-2
      id="jofemar-channel-list"
      [items]="items$ | async"
      [itemsPerPage]="itemsPerPage$ | async"
      [totalItems]="totalItems$ | async"
      [activeIndex]="activeIndex$ | async"
      [currentPage]="currentPage$ | async"
      (pageChange)="setPageNumber($event)"
      (itemsPerPageChange)="setItemsPerPage($event)"
      [filters]="filters"
    >
      <vdr-bulk-action-menu
        locationId="jofemar-channel-list"
        [hostComponent]="this"
        [selectionManager]="selectionManager"
      />
      <vdr-dt2-column
        [heading]="'common.id' | translate"
        [hiddenByDefault]="true"
      >
        <ng-template let-jofemarChannel="item">{{
          jofemarChannel.id
        }}</ng-template>
      </vdr-dt2-column>
      <vdr-dt2-column [heading]="'agromaat.agroflex.channelAlias' | translate">
        <ng-template let-jofemarChannel="item">{{
          jofemarChannel.channelAlias
        }}</ng-template>
      </vdr-dt2-column>
      <vdr-dt2-column [heading]="'common.image' | translate" id="image">
        <ng-template let-jofemarChannel="item">
          <div class="image-placeholder">
            <img
              *ngIf="
                jofemarChannel.productVariant?.featuredAsset ||
                  jofemarChannel.productVariant?.product.featuredAsset as asset;
                else imagePlaceholder
              "
              [src]="asset | assetPreview: 'tiny'"
            />
            <ng-template #imagePlaceholder>
              <div class="placeholder">
                <clr-icon shape="image" size="48"></clr-icon>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </vdr-dt2-column>
      <vdr-dt2-column [heading]="'catalog.variant' | translate">
        <ng-template let-jofemarChannel="item">
          {{
            jofemarChannel.productVariant
              ? jofemarChannel.productVariant.name
              : ""
          }}
        </ng-template>
      </vdr-dt2-column>
      <vdr-dt2-column
        [heading]="'agromaat.agroflex.amountInStock' | translate"
        [sort]="sorts.get('inStock')"
      >
        <ng-template let-jofemarChannel="item">{{
          jofemarChannel.inStock
        }}</ng-template>
      </vdr-dt2-column>
      <vdr-dt2-column
        [heading]="'common.view-contents' | translate"
        id="view-contents"
        [optional]="false"
      >
        <ng-template let-jofemarChannel="item">
          <a
            class="button-small bg-weight-150"
            [routerLink]="['./', { contents: jofemarChannel.id }]"
            queryParamsHandling="preserve"
          >
            <span>{{ "common.view-contents" | translate }}</span>
            <clr-icon shape="file-group"></clr-icon>
          </a>
        </ng-template>
      </vdr-dt2-column>
      <vdr-dt2-column
        [heading]="'common.created-at' | translate"
        id="created-at"
        [hiddenByDefault]="true"
        [sort]="sorts.get('createdAt')"
      >
        <ng-template let-productCell="item">
          {{ productCell.createdAt | localeDate: "short" }}
        </ng-template>
      </vdr-dt2-column>
      <vdr-dt2-column
        [heading]="'common.updated-at' | translate"
        id="updated-at"
        [hiddenByDefault]="true"
        [sort]="sorts.get('updatedAt')"
      >
        <ng-template let-productCell="item">
          {{ productCell.updatedAt | localeDate: "short" }}
        </ng-template>
      </vdr-dt2-column>
    </vdr-data-table-2>
  </ng-template>
  <ng-template
    vdrSplitViewRight
    [splitViewTitle]="activeJofemarChannelTitle$ | async"
  >
    <ng-container *ngIf="activeJofemarChannel$ | async as activeJofemarChannel">
      <jofemar-channel-detail
        [activeJofemarChannel]="activeJofemarChannel"
      ></jofemar-channel-detail>
    </ng-container>
  </ng-template>
</vdr-split-view>
