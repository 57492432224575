import gql from 'graphql-tag';

const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        name
        fileSize
        mimeType
        type
        preview
        source
    }
`;

export const PRODUCT_CELL_FRAGMENT = gql`
    fragment ProductCell on ProductCell {
        id
        createdAt
        updatedAt
        col
        row
        state
        type
        enabled
        productVariant {
            id
            name
            featuredAsset {
                ...Asset
            }
            product {
                featuredAsset {
                    ...Asset
                }
            }
            customFields {
                productCellTypes
            }
        }
        productModule {
            id
            name
            connectivityStatus
            lastConnectivityUpdate
            vending {
                cooling
            }
        }
    }

    ${ASSET_FRAGMENT}
`;

export const JOFEMAR_CHANNEL_FRAGMENT = gql`
    fragment JofemarChannel on JofemarChannel {
        id
        inStock
        channelAlias
        sequence
        channelNumber
        productVariant {
            id
            name
            featuredAsset {
                ...Asset
            }
            product {
                featuredAsset {
                    ...Asset
                }
            }
        }
        tray {
            id
            trayNumber
            channels {
                id
            }
        }
        channels {
            id
        }
        createdAt
        updatedAt
    }

    ${ASSET_FRAGMENT}
`;

export const JOFEMAR_TRAY_FRAGMENT = gql`
    fragment JofemarTray on JofemarTray {
        id
        trayNumber
        sequence
        jofemarChannels {
            ...JofemarChannel
        }
    }

    ${JOFEMAR_CHANNEL_FRAGMENT}
`;
