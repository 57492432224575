import gql from 'graphql-tag';

import { JOFEMAR_CHANNEL_FRAGMENT } from '../../common/fragments.graphql';

export const UPDATE_JOFEMAR_CHANNEL = gql`
    mutation UpdateJofemarChannel($input: UpdateJofemarChannelInput!) {
        updateJofemarChannel(input: $input) {
            ...JofemarChannel
        }
    }
    ${JOFEMAR_CHANNEL_FRAGMENT}
`;
