<form class="form" [formGroup]="detailForm">
  <section class="form-block">
    <div class="clr-row">
      <div class="clr-col-6">
        <vdr-form-field
          label="{{ 'catalog.variant' | translate }}"
          for="productVariant"
        >
          <filtered-relation-product-variant-input
            formControlName="productVariant"
            [parentFormControl]="detailForm.get('productVariant')"
            [config]="htmlFormInputConfigArgsDef"
            deliverDevice="jofemar"
            [readonly]="false"
            style="max-width: 100%"
          />
        </vdr-form-field>
      </div>
      <div class="clr-col-6">
        <vdr-form-field
          label="{{ 'agromaat.agroflex.amountInStock' | translate }}"
          for="inStock"
        >
          <input
            name="inStock"
            type="number"
            min="0"
            formControlName="inStock"
          />
        </vdr-form-field>
      </div>
    </div>
    <div class="clr-row clr-justify-content-end">
      <div class="clr-col-2">
        <button
          [disabled]="
            !detailForm.valid || !('ManageJofemarStock' | hasPermission)
          "
          class="btn btn-primary"
          (click)="update()"
        >
          {{ "common.update" | translate }}
        </button>
      </div>
    </div>
  </section>
</form>
