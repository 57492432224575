<vdr-page-block>
  <vdr-action-bar>
    <vdr-ab-left>
      <div class="agromaat-btn-group agromaat-btn-outline-primary mode-select">
        <div *ngFor="let option of modeOptions" class="radio agromaat-btn">
          <input
            type="radio"
            name="mode"
            [id]="option.id"
            [title]="option.title"
            [value]="option.value"
            [checked]="mode === option.value"
          />
          <label [for]="option.id" (click)="setMode(option.value)">
            <cds-icon
              *ngIf="option.iconShape === 'arrow'"
              [attr.direction]="option.iconDirection"
              [attr.shape]="option.iconShape"
            ></cds-icon>
            <clr-icon
              *ngIf="option.iconShape !== 'arrow'"
              [attr.shape]="option.iconShape"
            ></clr-icon>
          </label>
        </div>
      </div>
    </vdr-ab-left>
    <vdr-ab-right>
      <div class="flex clr-row">
        <button
          [hidden]="!('SuperAdmin' | hasPermission)"
          [disabled]="(jofemar$ | async)?.isSyncing"
          class="btn btn-secondary"
          (click)="syncJofemar()"
        >
          <span *ngIf="(jofemar$ | async)?.isSyncing">
            <clr-spinner clrInline />
          </span>
          Synchroniseer
        </button>
        &nbsp;
        <button
          [hidden]="!('SuperAdmin' | hasPermission)"
          class="btn btn-danger"
          (click)="resetToFactorySettings()"
        >
          Herstel naar fabrieksinstellingen
        </button>
      </div>
    </vdr-ab-right>
  </vdr-action-bar>
</vdr-page-block>

<vdr-page-detail-layout>
  <vdr-page-block>
    <clr-accordion class="accordion" [clrAccordionMultiPanel]="true">
      <ng-container
        *ngFor="let item of items$ | async; trackBy: trackByJofemarTrayId"
      >
        <clr-accordion-panel
          [clrAccordionPanelOpen]="panels[item.id] || true"
          (clrAccordionPanelOpenChange)="togglePanel(item.id)"
        >
          <clr-accordion-title>Rij {{ item.sequence }}</clr-accordion-title>
          <clr-accordion-content class="accordion-content">
            <div class="accordion-grid">
              <div
                *ngFor="
                  let jofemarChannel of item.jofemarChannels;
                  trackBy: trackByJofemarChannelId
                "
                class="accordion-grid-item"
                [ngClass]="{ 'in-editmode': mode !== 'home' }"
                (click)="mode !== 'home' ? update(jofemarChannel) : null"
              >
                <span class="in-stock">{{ jofemarChannel.inStock }}</span>
                <div
                  *ngIf="
                    jofemarChannel.productVariant as productVariant;
                    else noProduct
                  "
                >
                  <img
                    *ngIf="
                      productVariant.featuredAsset ||
                        productVariant.product.featuredAsset as asset;
                      else noImage
                    "
                    [src]="asset | assetPreview: 'thumb'"
                    alt="{{ jofemarChannel.channelAlias }} - {{
                      jofemarChannel.productVariant.name
                    }}"
                  />
                </div>
                <ng-template #noProduct>
                  <div class="fallback-product-div">
                    <p>Nog geen product gekoppeld</p>
                  </div>
                </ng-template>
                <ng-template #noImage>
                  <div class="fallback-image-div">
                    <p>
                      {{ jofemarChannel.productVariant.name }}
                    </p>
                  </div>
                </ng-template>
                <span class="channel-alias">
                  {{
                    jofemarChannel.channelAlias
                  }}
                  <div *ngIf="loadingStates[jofemarChannel.id]" class="loading-indicator">
                    <clr-spinner clrInline />
                  </div>
                </span>
              </div>
            </div>
          </clr-accordion-content>
        </clr-accordion-panel>
      </ng-container>
    </clr-accordion>
  </vdr-page-block>
</vdr-page-detail-layout>
