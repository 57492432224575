import gql from 'graphql-tag';

import { PRODUCT_CELL_FRAGMENT } from '../../common/fragments.graphql';

export const GET_PRODUCT_CELLS = gql`
    query GetProductCells($options: ProductCellListOptions) {
        productCells(options: $options) {
            items {
                ...ProductCell
            }
            totalItems
        }
    }
    ${PRODUCT_CELL_FRAGMENT}
`;

export const OPEN_PRODUCT_CELL = gql`
    mutation OpenProductCell($input: OpenProductCellInput!) {
        openProductCell(input: $input) {
            __typename
            state
        }
    }
`;

export const DELETE_PRODUCT_CELL = gql`
    mutation DeleteProductCell($input: DeleteProductCellInput!) {
        deleteProductCell(input: $input) {
            __typename
        }
    }
`;

export const BULK_OPEN_PRODUCT_CELL = gql`
    mutation BulkOpenProductCell($input: BulkOpenProductCellInput!) {
        bulkOpenProductCell(input: $input) {
            __typename
        }
    }
`;

export const BULK_UPDATE_PRODUCT_CELL_PRODUCT_VARIANT = gql`
    mutation bulkUpdateProductCellProductVariant($input: BulkUpdateProductCellProductVariantInput!) {
        bulkUpdateProductCellProductVariant(input: $input) {
            __typename
        }
    }
`;

export const BULK_UPDATE_PRODUCT_CELL_STATE = gql`
    mutation BulkUpdateProductCellState($input: BulkUpdateProductCellStateInput!) {
        bulkUpdateProductCellState(input: $input) {
            __typename
        }
    }
`;
