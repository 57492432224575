import {
    addNavMenuItem,
    addNavMenuSection,
    DataService,
    ModalService,
    NotificationService,
    registerBulkAction,
    registerFormInputComponent,
    registerPageTab,
    setDashboardWidgetLayout,
} from '@vendure/admin-ui/core';
import {
    BulkOpenProductCellMutation,
    BulkOpenProductCellMutationVariables,
    BulkUpdateJofemarChannelProductVariantMutation,
    BulkUpdateJofemarChannelProductVariantMutationVariables,
    BulkUpdateJofemarChannelStockMutation,
    BulkUpdateJofemarChannelStockMutationVariables,
    BulkUpdateProductCellProductVariantMutation,
    BulkUpdateProductCellProductVariantMutationVariables,
    BulkUpdateProductCellStateMutation,
    BulkUpdateProductCellStateMutationVariables,
    JofemarChannel,
    MeasureProductModuleQuery,
    ProductCell,
    ProductModule,
} from './generated-types';
import { Injector } from '@angular/core';
import {
    MEASURE_PRODUCT_MODULES,
    RESTART_PRODUCT_MODULES,
} from './components/product-modules-overview/product-modules-overview.graphql';
import {
    BULK_OPEN_PRODUCT_CELL,
    BULK_UPDATE_PRODUCT_CELL_PRODUCT_VARIANT,
    BULK_UPDATE_PRODUCT_CELL_STATE,
} from './components/product-cells-list/product-cells-list.graphql';
import { ProductCellsListComponent } from './components/product-cells-list/product-cells-list.component';
import { ProductCellStateSelectDialogComponent } from './components/product-cell-state-select-dialog/product-cell-state-select-dialog.component';
import { ProductVariantSelectDialogComponent } from './components/product-variant-select-dialog/product-variant-select-dialog.component';
import { JofemarChannelListComponent } from './components/jofemar-channel-list/jofemar-channel-list.component';
import {
    BULK_UPDATE_JOFEMAR_CHANNEL_PRODUCT_VARIANT,
    BULK_UPDATE_JOFEMAR_CHANNEL_STOCK,
} from './components/jofemar-channel-list-v1/jofemar-channel-list-v1.graphql';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DeliverDeviceFormInputComponent } from './components/deliver-device-form-input/deliver-device-form-input.component';
import { UpdateStockDialogComponent } from './components/update-stock-dialog/update-stock-dialog.component';
import { lastValueFrom } from 'rxjs';
import { JofemarChannelListV1Component } from './components/jofemar-channel-list-v1/jofemar-channel-list-v1.component';

export default [
    registerFormInputComponent('deliver-device-form-input', DeliverDeviceFormInputComponent),
    setDashboardWidgetLayout([]),
    registerBulkAction({
        location: 'product-modules-list',
        label: 'agromaat.bulk.restart-product-modules',
        icon: 'refresh',
        onClick: ({ selection, injector }: { selection: ProductModule[]; injector: Injector }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            dataService
                .mutate(RESTART_PRODUCT_MODULES, {
                    input: {
                        productModuleIds: ids,
                    },
                })
                .subscribe(
                    () => {
                        notificationService.success('Modules worden herstart');
                    },
                    err => {
                        notificationService.error(err);
                    },
                );
        },
    }),
    registerBulkAction({
        location: 'product-modules-list',
        label: 'agromaat.bulk.measure-product-modules',
        icon: 'cpu',
        onClick: async ({ selection, injector }: { selection: ProductModule[]; injector: Injector }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            for (const id of ids) {
                try {
                    const result = await lastValueFrom(
                        dataService.query<MeasureProductModuleQuery>(MEASURE_PRODUCT_MODULES, {
                            productModuleId: id,
                        }).single$,
                    );

                    notificationService.success(_('agromaat.bulk.measure-product-modules-success'), {
                        id,
                        chipTemperature: result.measureProductModule?.chipTemperature || 0,
                    });
                } catch {
                    notificationService.error(_('agromaat.bulk.measure-product-modules-failed'));
                }
            }
        },
    }),
    registerBulkAction({
        location: 'product-cells-list',
        label: 'agromaat.bulk.open-product-cell',
        icon: 'unlock',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: ProductCell[];
            injector: Injector;
            hostComponent: ProductCellsListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            dataService
                .mutate<BulkOpenProductCellMutation, BulkOpenProductCellMutationVariables>(
                    BULK_OPEN_PRODUCT_CELL,
                    {
                        input: {
                            ids,
                        },
                    },
                )
                .subscribe(
                    result => {
                        hostComponent.refresh();
                        if (result.bulkOpenProductCell.length !== ids.length) {
                            notificationService.error('common.notify-update-error', {
                                entity: 'Productvak',
                            });
                            return;
                        }
                        notificationService.success('common.notify-update-success', {
                            entity: 'Productvak',
                        });
                    },
                    () =>
                        notificationService.error('common.notify-update-error', {
                            entity: 'Productvak',
                        }),
                );
        },
    }),
    registerBulkAction({
        location: 'product-cells-list',
        label: 'agromaat.bulk.change-product-cell-state',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: ProductCell[];
            injector: Injector;
            hostComponent: ProductCellsListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(ProductCellStateSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                    },
                })
                .subscribe(res => {
                    if (res) {
                        dataService
                            .mutate<
                                BulkUpdateProductCellStateMutation,
                                BulkUpdateProductCellStateMutationVariables
                            >(BULK_UPDATE_PRODUCT_CELL_STATE, {
                                input: {
                                    ids,
                                    state: res,
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (result.bulkUpdateProductCellState.length !== ids.length) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'Productvak',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'Productvak',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'Productvak',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'product-cells-list',
        label: 'agromaat.bulk.change-product-variant',
        icon: 'edit',
        isVisible: ({ selection }: { selection: ProductCell[] }) =>
            new Set(selection.map(({ type, productModule }) => `${type}-${productModule.vending.cooling}`))
                .size === 1,
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: ProductCell[];
            injector: Injector;
            hostComponent: ProductCellsListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        // @TODO filter based on supported product cell types
                        deliverDevice: 'vending',
                        productCellType: selection.map(({ type }) => type)[0],
                        cooling: selection.map(({ productModule }) => productModule.vending.cooling)[0],
                    },
                })
                .subscribe(id => {
                    if (id) {
                        dataService
                            .mutate<
                                BulkUpdateProductCellProductVariantMutation,
                                BulkUpdateProductCellProductVariantMutationVariables
                            >(BULK_UPDATE_PRODUCT_CELL_PRODUCT_VARIANT, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (result.bulkUpdateProductCellProductVariant.length !== ids.length) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'Productvak',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'Productvak',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'Productvak',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'jofemar-channel-list',
        label: 'agromaat.bulk.change-product-variant',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: JofemarChannel[];
            injector: Injector;
            hostComponent: JofemarChannelListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        deliverDevice: 'jofemar',
                    },
                })
                .subscribe(id => {
                    if (id) {
                        dataService
                            .mutate<
                                BulkUpdateJofemarChannelProductVariantMutation,
                                BulkUpdateJofemarChannelProductVariantMutationVariables
                            >(BULK_UPDATE_JOFEMAR_CHANNEL_PRODUCT_VARIANT, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (result.bulkUpdateJofemarChannelProductVariant.length !== ids.length) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFlex kanaal',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'AgroFlex kanaal',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFlex kanaal',
                                    }),
                            );
                    }
                });
        },
    }),
    registerBulkAction({
        location: 'jofemar-channel-list',
        label: 'agromaat.bulk.change-stock',
        icon: 'edit',
        onClick: ({
            selection,
            injector,
            hostComponent,
        }: {
            selection: JofemarChannel[];
            injector: Injector;
            hostComponent: JofemarChannelListComponent;
        }) => {
            const ids = selection.map(({ id }) => id);
            const modalService = injector.get(ModalService);
            const dataService = injector.get(DataService);
            const notificationService = injector.get(NotificationService);
            modalService
                .fromComponent(UpdateStockDialogComponent, {
                    size: 'md',
                    locals: {
                        stock: 0,
                    },
                })
                .subscribe(inStock => {
                    if (inStock !== undefined) {
                        dataService
                            .mutate<
                                BulkUpdateJofemarChannelStockMutation,
                                BulkUpdateJofemarChannelStockMutationVariables
                            >(BULK_UPDATE_JOFEMAR_CHANNEL_STOCK, {
                                input: {
                                    ids,
                                    inStock,
                                },
                            })
                            .subscribe(
                                result => {
                                    hostComponent.refresh();
                                    if (result.bulkUpdateJofemarChannelStock.length !== ids.length) {
                                        notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFlex kanaal',
                                        });
                                        return;
                                    }
                                    notificationService.success('common.notify-update-success', {
                                        entity: 'AgroFlex kanaal',
                                    });
                                },
                                () =>
                                    notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFlex kanaal',
                                    }),
                            );
                    }
                });
        },
    }),
    addNavMenuSection(
        {
            id: 'agromaat',
            label: 'agromaat.common.agromaat',
            items: [
                {
                    id: 'product-agrobox',
                    label: 'agromaat.common.agrobox',
                    routerLink: ['/extensions/agromaat-stock/agrobox'],
                    requiresPermission: 'ManageVendingStock',
                },
                {
                    id: 'product-agrobox-refill',
                    label: 'agromaat.common.agrobox-refill',
                    routerLink: ['/extensions/agromaat-stock/agrobox-refill'],
                    requiresPermission: 'ManageVendingStock',
                },
                {
                    id: 'agroflex-channels',
                    label: 'agromaat.agroflex.agroflex',
                    routerLink: ['/extensions/agromaat-stock/agroflex-channels'],
                    requiresPermission: 'ManageJofemarStock',
                },
                {
                    id: 'agrotap',
                    label: 'agromaat.common.agrotap',
                    routerLink: ['/extensions/agromaat-stock/agrotap'],
                    requiresPermission: 'ManageMilktapStock',
                },
            ],
        },
        'catalog',
    ),
    addNavMenuItem(
        {
            id: 'product-modules',
            label: 'agromaat.common.productModules',
            routerLink: ['/extensions/agromaat-stock/product-modules'],
            requiresPermission: 'SuperAdmin',
        },
        'settings',
    ),
    addNavMenuItem(
        {
            id: 'graphql-sandbox',
            label: 'Apollo Sandbox',
            routerLink: ['/extensions/agromaat-stock/graphql-sandbox'],
            requiresPermission: 'SuperAdmin',
        },
        'settings',
    ),
    registerPageTab({
        location: 'agroflex-channels' as any,
        tab: 'Bijvullen',
        route: '/extensions/agromaat-stock/agroflex-channels',
        component: JofemarChannelListComponent,
    }),
    registerPageTab({
        location: 'agroflex-channels' as any,
        tab: 'Overzicht',
        route: '/extensions/agromaat-stock/agroflex-channels/v1',
        component: JofemarChannelListV1Component,
    }),
];
