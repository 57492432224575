import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    GetJofemarChannelDocument,
    GetJofemarChannelQuery,
    GetProductModulesForJofemarQuery,
    ResetJofemarMutation,
    SyncJofemarMutation,
} from '../../generated-types';
import {
    DataService,
    ItemOf,
    ModalService,
    NotificationService,
    SharedModule,
    TypedBaseListComponent,
} from '@vendure/admin-ui/core';
import { BehaviorSubject, combineLatest, EMPTY, interval, merge, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import {
    GET_PRODUCT_MODULES_FOR_JOFEMAR,
    RESET_JOFEMAR,
    SYNC_JOFEMAR,
} from './jofemar-channel-list-v1.graphql';
import { JofemarChannelDetailComponent } from '../jofemar-channel-detail/jofemar-channel-detail.component';

@Component({
    selector: 'jofemar-channel-list-v1',
    templateUrl: './jofemar-channel-list-v1.component.html',
    styleUrls: ['./jofemar-channel-list-v1.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, JofemarChannelDetailComponent],
})
export class JofemarChannelListV1Component
    extends TypedBaseListComponent<typeof GetJofemarChannelDocument, 'jofemarChannels'>
    implements OnInit
{
    activeJofemarChannel$: Observable<ItemOf<GetJofemarChannelQuery, 'jofemarChannels'> | undefined>;
    activeJofemarChannelTitle$: Observable<string>;
    activeIndex$: Observable<number>;
    jofemar$: Observable<{ id: string; isSyncing: boolean }>;
    selectedMemberIds: string[] = [];
    itemsPerPage$ = new BehaviorSubject(50);
    refreshJofemar$ = new BehaviorSubject<undefined>(undefined);

    readonly filters = this.createFilterCollection()
        .addIdFilter()
        .addFilter({
            name: 'channelNumber',
            type: {
                kind: 'number',
            },
            label: 'agromaat.agroflex.channelNumber',
            filterField: 'channelNumber',
        })
        .addFilter({
            name: 'inStock',
            type: {
                kind: 'number',
            },
            label: 'agromaat.agroflex.amountInStock',
            filterField: 'inStock',
        })
        .addDateFilters()
        .connectToRoute(this.route);

    readonly sorts = this.createSortCollection()
        .addSort({ name: 'createdAt' })
        .addSort({ name: 'updatedAt' })
        .addSort({ name: 'channelNumber' })
        .addSort({ name: 'inStock' })
        .connectToRoute(this.route);

    constructor(
        private readonly modalService: ModalService,
        protected dataService: DataService,
        private readonly notificationService: NotificationService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
        super.configure({
            document: GetJofemarChannelDocument,
            getItems: data => data.jofemarChannels,
            setVariables: (skip, take) => ({
                options: {
                    skip,
                    take,
                    filter: {
                        ...this.filters.createFilterInput(),
                    },
                    sort: this.sorts.createSortInput(),
                },
            }),
            refreshListOnChanges: [this.filters.valueChanges, this.sorts.valueChanges],
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        const jofemarChannelId$ = this.route.paramMap.pipe(
            map(pm => pm.get('contents')),
            distinctUntilChanged(),
            tap(() => (this.selectedMemberIds = [])),
        );
        this.activeJofemarChannel$ = combineLatest(this.items$, jofemarChannelId$).pipe(
            map(([jofemarChannels, jofemarChannelId]) => {
                if (jofemarChannelId) {
                    return jofemarChannels.find(z => z.id === jofemarChannelId);
                }
            }),
        );
        this.activeJofemarChannelTitle$ = this.activeJofemarChannel$.pipe(
            map(
                activeJofemarChannel =>
                    `${activeJofemarChannel?.tray.trayNumber} - ${activeJofemarChannel?.channelNumber}`,
            ),
        );
        this.activeIndex$ = combineLatest(this.items$, jofemarChannelId$).pipe(
            map(([jofemarChannels, jofemarChannelId]) => {
                if (jofemarChannelId) {
                    return jofemarChannels.findIndex(g => g.id === jofemarChannelId);
                } else {
                    return -1;
                }
            }),
        );
        this.refreshJofemar$
            .pipe(
                switchMap(() =>
                    merge(
                        timer(0), // Emit immediately
                        interval(5000), // Continue emitting every 5 seconds
                    ).pipe(
                        // Start an interval that emits every 5 seconds
                        switchMap(() => {
                            if (!this.permissionsService.userHasPermissions(['SuperAdmin'])) {
                                return of();
                            }
                            return this.dataService
                                .query<GetProductModulesForJofemarQuery>(GET_PRODUCT_MODULES_FOR_JOFEMAR)
                                .mapSingle(data => {
                                    for (const item of data.productModules.items) {
                                        if (item.__typename === 'JofemarModule') {
                                            this.jofemar$ = of(item.jofemar);
                                            this.changeDetectorRef.detectChanges();
                                            return item.jofemar; // Return the jofemar object
                                        }
                                    }
                                    throw new Error('No JofemarModule found');
                                });
                        }),
                        takeWhile(jofemar => jofemar?.isSyncing, true), // Continue polling as long as isSyncing is true
                    ),
                ),
                takeUntil(this.destroy$), // Clean up the subscription when the component is destroyed
            )
            .subscribe(this.refresh);
    }

    closeChannels() {
        const params = structuredClone(this.route.snapshot.params);
        delete params.contents;
        this.router.navigate(['./', params], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
        });
    }

    resetToFactorySettings() {
        combineLatest(
            this.modalService.dialog({
                title: 'agromaat.dialog.reset-agroflex.title',
                buttons: [
                    { label: 'Cancel', type: 'secondary' },
                    { label: 'Reset', type: 'danger', returnValue: true },
                ],
                body: 'agromaat.dialog.reset-agroflex.body',
            }),
            this.jofemar$,
        )
            .pipe(
                switchMap(([response, jofemar]): Observable<never> | Observable<ResetJofemarMutation> =>
                    response
                        ? this.dataService.mutate(RESET_JOFEMAR, {
                              input: {
                                  jofemarId: jofemar.id,
                              },
                          })
                        : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.resetJofemar.success) {
                    this.notificationService.success('agromaat.dialog.reset-agroflex.success');
                } else {
                    this.notificationService.error('agromaat.dialog.reset-agroflex.failed');
                }
            });
    }

    syncJofemar() {
        combineLatest(
            this.modalService.dialog({
                title: 'agromaat.dialog.start-sync-agroflex.title',
                buttons: [
                    { label: 'Annuleren', type: 'secondary' },
                    { label: 'Synchroniseren', type: 'primary', returnValue: true },
                ],
                body: 'agromaat.dialog.start-sync-agroflex.body',
            }),
            this.jofemar$,
        )
            .pipe(
                switchMap(([response, jofemar]): Observable<never> | Observable<SyncJofemarMutation> =>
                    response
                        ? this.dataService.mutate(SYNC_JOFEMAR, {
                              input: {
                                  jofemarId: jofemar.id,
                              },
                          })
                        : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.syncJofemar.started) {
                    this.notificationService.success('agromaat.dialog.start-sync-agroflex.success');
                } else {
                    this.notificationService.error('agromaat.dialog.start-sync-agroflex.failed');
                }
                this.jofemar$ = this.jofemar$.pipe(
                    map(jofemar => ({
                        ...jofemar,
                        isSyncing: true,
                    })),
                );
                setTimeout(() => this.refreshJofemar(), 5000);
            });
    }

    refreshJofemar() {
        return this.refreshJofemar$?.next(undefined);
    }
}
