import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    BulkUpdateJofemarChannelProductVariantMutation,
    BulkUpdateJofemarChannelProductVariantMutationVariables,
    GetJofemarTraysQuery,
    GetProductModulesForJofemarQuery,
    ResetJofemarMutation,
    SyncJofemarMutation,
} from '../../generated-types';
import {
    DataService,
    ModalService,
    NotificationService,
    PermissionsService,
    SharedModule,
} from '@vendure/admin-ui/core';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    EMPTY,
    finalize,
    interval,
    merge,
    Observable,
    of,
    Subject,
    timer,
} from 'rxjs';
import { debounceTime, groupBy, map, mergeMap, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import {
    BULK_UPDATE_JOFEMAR_CHANNEL_STOCK,
    GET_JOFEMAR_TRAYS,
    GET_PRODUCT_MODULES_FOR_JOFEMAR,
    RESET_JOFEMAR,
    SYNC_JOFEMAR,
    UPDATE_JOFEMAR_CHANNEL_PRODUCT_VARIANT,
} from './jofemar-channel-list.graphql';
import { ProductVariantSelectDialogComponent } from '../product-variant-select-dialog/product-variant-select-dialog.component';

type Modes = 'home' | 'edit' | 'increase' | 'decrease';

@Component({
    selector: 'agroflex-channels',
    templateUrl: './jofemar-channel-list.component.html',
    styleUrls: ['./jofemar-channel-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class JofemarChannelListComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    jofemar$: Observable<{ id: string; isSyncing: boolean }>;
    private refreshJofemar$ = new BehaviorSubject<void>(undefined);
    private itemsSubject = new BehaviorSubject<GetJofemarTraysQuery['jofemarTrays']['items']>([]);
    items$ = this.itemsSubject.asObservable();
    private updateStockSubject = new Subject<{ id: string; inStock: number }>();
    panels: { [key: string]: boolean } = {};
    private stockUpdates: { [key: string]: number } = {};
    private loadingStates: { [key: string]: boolean } = {};
    private previousStocks: { [key: string]: number } = {};
    @Input() mode: Modes = 'home';
    modeOptions = [
        {
            id: 'home',
            value: 'home',
            title: 'Bekijk voorraad',
            iconShape: 'home',
            iconDirection: '',
        },
        {
            id: 'increase',
            value: 'increase',
            title: 'Verhoog voorraad',
            iconShape: 'arrow',
            iconDirection: '',
        },
        {
            id: 'decrease',
            value: 'decrease',
            title: 'Verlaag voorraad',
            iconShape: 'arrow',
            iconDirection: 'down',
        },
        {
            id: 'edit',
            value: 'edit',
            title: 'Wijzig product',
            iconShape: 'pencil',
            iconDirection: '',
        },
    ];

    constructor(
        private readonly modalService: ModalService,
        private readonly dataService: DataService,
        private readonly notificationService: NotificationService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly permissionsService: PermissionsService,
    ) {}

    ngOnInit(): void {
        this.dataService
            .query<GetJofemarTraysQuery>(GET_JOFEMAR_TRAYS)
            .mapSingle(data => data.jofemarTrays.items)
            .subscribe(items => this.itemsSubject.next(items));

        this.refreshJofemar$
            .pipe(
                switchMap(() =>
                    merge(
                        timer(0), // Emit immediately
                        interval(5000), // Continue emitting every 5 seconds
                    ).pipe(
                        switchMap(() =>
                            this.permissionsService.userHasPermissions(['SuperAdmin'])
                                ? this.dataService
                                      .query<GetProductModulesForJofemarQuery>(
                                          GET_PRODUCT_MODULES_FOR_JOFEMAR,
                                      )
                                      .mapSingle(data => {
                                          const jofemarModule = data.productModules.items.find(
                                              item => item.__typename === 'JofemarModule',
                                          );
                                          if (jofemarModule?.__typename === 'JofemarModule') {
                                              this.jofemar$ = of(jofemarModule.jofemar);
                                              this.changeDetectorRef.markForCheck();
                                              return jofemarModule.jofemar;
                                          }
                                          throw new Error('No JofemarModule found');
                                      })
                                : of(null),
                        ),
                        takeWhile(jofemar => jofemar !== null && jofemar.isSyncing, true),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe();

        this.updateStockSubject
            .pipe(
                groupBy(stockUpdate => stockUpdate.id),
                mergeMap(group$ =>
                    group$.pipe(
                        debounceTime(300),
                        switchMap(({ id, inStock }) => {
                            const input = {
                                ids: [id],
                                inStock,
                            };
                            this.loadingStates[id] = true;
                            this.changeDetectorRef.markForCheck();
                            return this.dataService.mutate(BULK_UPDATE_JOFEMAR_CHANNEL_STOCK, { input }).pipe(
                                map(() => ({ id, inStock: input.inStock })),
                                finalize(() => {
                                    delete this.previousStocks[id];
                                    this.loadingStates[id] = false;
                                    this.changeDetectorRef.markForCheck();
                                }),
                                catchError(() => {
                                    // If mutation fails, revert stock to previous value
                                    this.stockUpdates[id] = this.previousStocks[id];
                                    const revertedItems = this.itemsSubject.value.map(item => ({
                                        ...item,
                                        jofemarChannels: item.jofemarChannels.map(jofemarChannel =>
                                            jofemarChannel.id === id
                                                ? { ...jofemarChannel, inStock: this.previousStocks[id] }
                                                : jofemarChannel,
                                        ),
                                    }));
                                    this.itemsSubject.next(revertedItems);
                                    this.notificationService.error(
                                        'Er is een fout opgetreden bij het bijwerken van de voorraad. Probeer het opnieuw.',
                                    );
                                    this.loadingStates[id] = false;
                                    this.changeDetectorRef.markForCheck();
                                    return EMPTY;
                                }),
                            );
                        }),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    setMode(mode: Modes): void {
        this.mode = mode;
    }

    togglePanel(panel: string): void {
        this.panels[panel] = !this.panels[panel];
    }

    trackByJofemarTrayId(index: number, item: GetJofemarTraysQuery['jofemarTrays']['items'][0]): string {
        return item.id;
    }

    trackByJofemarChannelId(index: number, item: GetJofemarTraysQuery['jofemarTrays']['items'][0]): string {
        return item.id;
    }

    resetToFactorySettings(): void {
        combineLatest([
            this.modalService.dialog({
                title: 'agromaat.dialog.reset-agroflex.title',
                buttons: [
                    { label: 'Cancel', type: 'secondary' },
                    { label: 'Reset', type: 'danger', returnValue: true },
                ],
                body: 'agromaat.dialog.reset-agroflex.body',
            }),
            this.jofemar$,
        ])
            .pipe(
                switchMap(([response, jofemar]) =>
                    response
                        ? this.dataService.mutate<ResetJofemarMutation>(RESET_JOFEMAR, {
                              input: { jofemarId: jofemar.id },
                          })
                        : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.resetJofemar.success) {
                    this.notificationService.success('agromaat.dialog.reset-agroflex.success');
                } else {
                    this.notificationService.error('agromaat.dialog.reset-agroflex.failed');
                }
            });
    }

    syncJofemar(): void {
        combineLatest([
            this.modalService.dialog({
                title: 'agromaat.dialog.start-sync-agroflex.title',
                buttons: [
                    { label: 'Annuleren', type: 'secondary' },
                    { label: 'Synchroniseren', type: 'primary', returnValue: true },
                ],
                body: 'agromaat.dialog.start-sync-agroflex.body',
            }),
            this.jofemar$,
        ])
            .pipe(
                switchMap(([response, jofemar]) =>
                    response
                        ? this.dataService.mutate<SyncJofemarMutation>(SYNC_JOFEMAR, {
                              input: { jofemarId: jofemar.id },
                          })
                        : EMPTY,
                ),
            )
            .subscribe(result => {
                if (result.syncJofemar.started) {
                    this.notificationService.success('agromaat.dialog.start-sync-agroflex.success');
                } else {
                    this.notificationService.error('agromaat.dialog.start-sync-agroflex.failed');
                }
                this.jofemar$ = this.jofemar$.pipe(
                    map(jofemar => ({
                        ...jofemar,
                        isSyncing: true,
                    })),
                );
                setTimeout(() => this.refreshJofemar(), 5000);
            });
    }

    refreshJofemar(): void {
        this.refreshJofemar$.next();
    }

    refresh() {}

    update({
        id,
        inStock,
        productVariant,
    }: {
        id: string;
        inStock: number;
        productVariant: { id: string };
    }): void {
        if (this.mode === 'edit') {
            const ids = [id];
            this.modalService
                .fromComponent(ProductVariantSelectDialogComponent, {
                    locals: {
                        cancellable: true,
                        deliverDevice: 'jofemar',
                        excludeIds: productVariant ? [productVariant.id] : [],
                    },
                })
                .subscribe(id => {
                    if (id) {
                        this.dataService
                            .mutate<
                                BulkUpdateJofemarChannelProductVariantMutation,
                                BulkUpdateJofemarChannelProductVariantMutationVariables
                            >(UPDATE_JOFEMAR_CHANNEL_PRODUCT_VARIANT, {
                                input: {
                                    ids,
                                    productVariantId: id[0],
                                },
                            })
                            .subscribe(
                                result => {
                                    if (
                                        result.bulkUpdateJofemarChannelProductVariant[0].__typename !==
                                        'JofemarChannel'
                                    ) {
                                        this.notificationService.error('common.notify-update-error', {
                                            entity: 'AgroFlex kanaal',
                                        });
                                        return;
                                    }

                                    const updatedJofemarChannel =
                                        result.bulkUpdateJofemarChannelProductVariant[0];
                                    const updatedItems = this.itemsSubject.value.map(item => ({
                                        ...item,
                                        jofemarChannels: item.jofemarChannels.map(jofemarChannel =>
                                            jofemarChannel.id === (updatedJofemarChannel as any).id
                                                ? updatedJofemarChannel
                                                : jofemarChannel,
                                        ),
                                    }));
                                    this.itemsSubject.next(updatedItems as any);
                                },
                                () =>
                                    this.notificationService.error('common.notify-update-error', {
                                        entity: 'AgroFlex kanaal',
                                    }),
                            );
                    }
                });
            return;
        }

        if (this.previousStocks[id] === undefined) {
            this.previousStocks[id] = inStock;
        }

        // Immediately update the local stock state
        this.stockUpdates[id] = Math.max(0, inStock + (this.mode === 'increase' ? 1 : -1));

        // Update the UI immediately
        const updatedItems = this.itemsSubject.value.map(item => ({
            ...item,
            jofemarChannels: item.jofemarChannels.map(jofemarChannel =>
                jofemarChannel.id === id
                    ? { ...jofemarChannel, inStock: this.stockUpdates[id] }
                    : jofemarChannel,
            ),
        }));
        this.itemsSubject.next(updatedItems);

        // Emit the event to trigger to debounce
        this.updateStockSubject.next({ id, inStock: this.stockUpdates[id] });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
