import gql from 'graphql-tag';

export const GET_PRODUCT_MODULES = gql`
    query GetProductModules($options: ProductModuleListOptions) {
        productModules(options: $options) {
            items {
                __typename
                id
                connectivityStatus
                lastConnectivityUpdate
                serialNumber
                latestMeasurement {
                    chipTemperature
                    createdAt
                }
                createdAt
                updatedAt
            }
            totalItems
        }
    }
`;

export const RESTART_PRODUCT_MODULES = gql`
    mutation RestartProductModules($input: RestartProductModulesInput!) {
        restartProductModules(input: $input) {
            id
        }
    }
`;

export const MEASURE_PRODUCT_MODULES = gql`
    query MeasureProductModule($productModuleId: ID!) {
        measureProductModule(productModuleId: $productModuleId) {
            chipTemperature
            createdAt
        }
    }
`;
