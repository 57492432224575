import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { DataService, ItemOf, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { GetJofemarChannelQuery, ProductVariant } from '../../generated-types';
import { FormBuilder, Validators } from '@angular/forms';
import { UPDATE_JOFEMAR_CHANNEL } from './jofemar-channel-detail.graphql';
import { FilteredRelationProductVariantInputComponent } from '../filtered-vdr-relation-product-variant-input/filtered-relation-product-variant-input.component';

@Component({
    selector: 'jofemar-channel-detail',
    templateUrl: './jofemar-channel-detail.component.html',
    styleUrls: ['./jofemar-channel-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [SharedModule, FilteredRelationProductVariantInputComponent],
})
export class JofemarChannelDetailComponent implements OnInit, OnChanges {
    @Input() activeJofemarChannel: ItemOf<GetJofemarChannelQuery, 'jofemarChannels'>;

    htmlFormInputConfigArgsDef = {
        name: 'productVariant',
        type: 'relation',
        list: false,
        required: true,
        entity: 'ProductVariant',
    };

    detailForm = this.formBuilder.group({
        productVariant: [null as Pick<ProductVariant, 'id'> | null, Validators.required],
        inStock: [1, Validators.required],
    });

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly notificationService: NotificationService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('activeJofemarChannel' in changes) {
            this.ngOnInit();
        }
    }

    ngOnInit(): void {
        if (this.activeJofemarChannel?.productVariant?.id) {
            this.detailForm.setValue({
                productVariant: this.activeJofemarChannel?.productVariant,
                inStock: this.activeJofemarChannel?.inStock,
            });
        }
    }

    update() {
        const productVariant = this.detailForm.get('productVariant')?.value;
        const input = {
            id: this.activeJofemarChannel?.id,
            channelNumber: this.activeJofemarChannel?.channelNumber,
            trayId: this.activeJofemarChannel?.tray?.id,
            productVariantId: productVariant?.id,
            inStock: this.detailForm.get('inStock')?.value,
            sequence: this.activeJofemarChannel?.sequence,
        };
        this.dataService.mutate(UPDATE_JOFEMAR_CHANNEL, { input }).subscribe(
            () => {
                this.detailForm.markAsPristine();
                this.changeDetector.markForCheck();
                this.notificationService.success('common.notify-update-success', {
                    entity: 'AgroFlex kanaal',
                });
            },
            () => {
                this.notificationService.error('common.notify-update-error', {
                    entity: 'AgroFlex kanaal',
                });
            },
        );
    }
}
