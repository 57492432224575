import gql from 'graphql-tag';

import { JOFEMAR_CHANNEL_FRAGMENT } from '../../common/fragments.graphql';

export const GET_JOFEMAR_CHANNELS = gql`
    query GetJofemarChannel($options: JofemarChannelListOptions) {
        jofemarChannels(options: $options) {
            items {
                ...JofemarChannel
            }
            totalItems
        }
    }
    ${JOFEMAR_CHANNEL_FRAGMENT}
`;

export const RESET_JOFEMAR = gql`
    mutation ResetJofemar($input: ResetJofemarInput!) {
        resetJofemar(input: $input) {
            success
        }
    }
`;

export const SYNC_JOFEMAR = gql`
    mutation SyncJofemar($input: SyncJofemarInput!) {
        syncJofemar(input: $input) {
            started
        }
    }
`;

export const GET_PRODUCT_MODULES_FOR_JOFEMAR = gql`
    query GetProductModulesForJofemar($options: ProductModuleListOptions) {
        productModules(options: $options) {
            items {
                ... on JofemarModule {
                    jofemar {
                        id
                        isSyncing
                    }
                }
            }
            totalItems
        }
    }
`;

export const BULK_UPDATE_JOFEMAR_CHANNEL_PRODUCT_VARIANT = gql`
    mutation bulkUpdateJofemarChannelProductVariant($input: BulkUpdateJofemarChannelProductVariantInput!) {
        bulkUpdateJofemarChannelProductVariant(input: $input) {
            __typename
        }
    }
`;

export const BULK_UPDATE_JOFEMAR_CHANNEL_STOCK = gql`
    mutation bulkUpdateJofemarChannelStock($input: BulkUpdateJofemarChannelStockInput!) {
        bulkUpdateJofemarChannelStock(input: $input) {
            __typename
        }
    }
`;
